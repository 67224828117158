import '../style/imagesection.css';
import { Href } from './button/Href';

type ImageSectionPropsType = {
    textSide: 'right' | 'left';
    title: string;
    paragraph: string;
    img: {
        src: string;
        alt: string;
        'aria-hidden'?: 'true' | 'false';
    };
    links: Array<{
        to: string;
        label: string;
    }>;
};

export const ImageSection = (props: ImageSectionPropsType) => {
    return (
        <section className={`${props.textSide}-section`}>
            <div className="section-content">
                <h3>{props.title}</h3>
                <p>{props.paragraph}</p>
                {props.links.map((link, index) => (
                    <Href key={link.to} to={link.to} variant="orange" outline={!(index % 2)} round>
                        {link.label}
                    </Href>
                ))}
            </div>
            <img src={props.img.src} alt={props.img.alt} aria-hidden={props.img['aria-hidden']} />
        </section>
    );
};
