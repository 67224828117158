export const J_URL = {
    JAMESPOT: 'https://www.jamespot.com/?utm_source=siteweb&utm_medium=jland',
    LAUNCH_PLATFORM: 'https://launch.jamespot.com/?utm_source=siteweb&utm_medium=jland',
    LAUNCH_OFFICE: 'https://launch.jamespot.com/?vertical=jland&utm_source=siteweb&utm_medium=jland',
    APPSTORE: 'https://appstore.jamespot.com//store/fr/?vertical=jland&utm_source=siteweb&utm_medium=jland',

    JAMESPOT_OPEN_AGORA: 'https://www.fr.jamespot.com/open-agora/?utm_source=siteweb&utm_medium=jland',
    JAMESPOT_FAST_INTRANET: 'https://www.fr.jamespot.com/fast-intranet/?utm_source=siteweb&utm_medium=jland',
    JAMESPOT_DIAPAZONE: 'https://www.fr.jamespot.com/diapazone/?utm_source=siteweb&utm_medium=jland',
    JAMESPOT_WEDOC: 'https://www.fr.jamespot.com/wedoc/?utm_source=siteweb&utm_medium=jland',
};
