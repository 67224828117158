import * as React from 'react';

export type MouseEvents = 'click' | 'mousedown' | 'mouseup';
export type TouchEvents = 'touchstart' | 'touchend';

export interface ClickAwayListenerProps extends React.HTMLAttributes<HTMLElement> {
    onClickAway: (event: MouseEvent | TouchEvent) => void;
    mouseEvent?: MouseEvents;
    touchEvent?: TouchEvents;
    as?: React.ElementType;
    className?: string;
}

/**
 * Wrap a component with a <div/> (or another component provided as the 'as' props).
 * Listen to outer mouse & touch events and call the onClickAway callback
 */
export function ClickAway({
    as = 'div',
    onClickAway,
    mouseEvent = 'click',
    touchEvent = 'touchend',
    ...props
}: ClickAwayListenerProps) {
    const node = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
        const handleEvents = (event: MouseEvent | TouchEvent): void => {
            if (node.current && node.current.contains(event.target as Node)) {
                return;
            }
            onClickAway(event);
        };
        document.addEventListener(mouseEvent, handleEvents);
        document.addEventListener(touchEvent, handleEvents);
        return () => {
            document.removeEventListener(mouseEvent, handleEvents);
            document.removeEventListener(touchEvent, handleEvents);
        };
    }, [mouseEvent, onClickAway, touchEvent]);

    return React.createElement(as, { ref: node, ...props });
}
