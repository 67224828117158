import { Href } from '../button/Href';
import { Img } from '../img/Img';
import './card-img.css';

export type CardImgProps = {
    title: string;
    subtitle: string;
    links: Array<{
        label: string;
        to: string;
    }>;
    img: any;
    alt: string;
};

export function CardImg({ title, subtitle, links, img, alt }: CardImgProps) {
    return (
        <div className="card-img sky mongolfiere">
            <div className="max-width grid">
                <div className="flex-col">
                    <h1>{title}</h1>
                    <p className="subtitle">{subtitle}</p>
                    {links.map((link, index) => (
                        <Href key={link.to} to={link.to} variant="sea-blue" outline={!!(index % 2)}>
                            {link.label}
                        </Href>
                    ))}
                </div>
                <Img src={img} height="400" alt={alt} />
            </div>
        </div>
    );
}
