import React from 'react';
import './loader.css';

export function Loader(props: { postPending: boolean }) {
    const [tmt, setTmt] = React.useState(false);

    React.useEffect(() => {
        setTimeout(() => {
            setTmt(true);
        }, 0);
    }, []);

    return <div className={`full-screen loader ${!props.postPending && tmt ? 'opacity' : ''}`} />;
}
