import { io } from 'socket.io-client';

const URL = window.__RUNTIME_CONFIG__.JLAND_IO_URL;

export const socket = io(URL, {
    path: '/jland/io',
    transports: ['websocket'],
    upgrade: false,
});

socket.connect();
