import React from 'react';
import { IntlProvider } from 'react-intl';
import { getLang, getNavigatorLanguage } from './utils/lang';
import './style/base.css';
import './style/scrollbar.css';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { Home } from './routes/Home';
import { AirSpot } from './routes/AirSpot';
import { LocationLayout } from './components/layout/LocationLayout';
import { Layout } from './components/layout/Layout';
import { UserContextProvider } from './context/UserContext';
import { CGU } from './routes/CGU';
import { WhoWeAre } from './routes/WhoWeAre';
import { WorldOfJLand } from './routes/WorldOfJLand';
import { PrivacyPolicy } from './routes/PrivacyPolicy';
import { CookieNotice } from './routes/CookieNotice';
import { ScrollToTop } from './components/layout/ScrollToTop';
import { AdminStats } from './routes/AdminStats';
import { AdminLayout } from './components/layout/AdminLayout';
import { WorkAdventureIframe } from './components/WorkAdventureIframe';
import { Offices } from './routes/Offices';
import { MapPresentation } from './routes/MapPresentation';
import { Summit } from './routes/Summit';
import { JDay } from './routes/JDay';
import { AdminMessage } from './routes/AdminMessages';

function App() {
    const [locale] = React.useState(getNavigatorLanguage());

    return (
        <UserContextProvider>
            <IntlProvider
                messages={getLang(locale)}
                locale={locale}
                defaultLocale="fr"
                onError={() => {
                    /**/
                }}>
                <BrowserRouter>
                    <ScrollToTop>
                        <Routes>
                            <Route path="/" element={<LocationLayout />}>
                                <Route path="/" element={<Layout />}>
                                    <Route index element={<Home />} />
                                    <Route path="world-of-jland" element={<WorldOfJLand />} />
                                    <Route path="who-we-are" element={<WhoWeAre />} />
                                    <Route path="offices" element={<Offices />} />
                                    <Route path="cgu" element={<CGU />} />
                                    <Route path="politique-de-confidentialite" element={<PrivacyPolicy />} />
                                    <Route path="gestion-des-cookies" element={<CookieNotice />} />

                                    <Route path="admin" element={<AdminLayout />}>
                                        <Route path="stats" element={<AdminStats />} />
                                        <Route path="summit" element={<AdminMessage target="summit" />} />
                                        <Route path="jday" element={<AdminMessage target="jday" />} />
                                    </Route>
                                    <Route path="voir-les-maps" element={<MapPresentation />} />
                                </Route>
                                {/** handle nicer URLs and old paths */}
                                {/** redirect old path to private map to the ecosysteme private map */}
                                <Route
                                    path="/custom/ecosysteme.jamespot.pro/private"
                                    element={<Navigate to="/map/ecosysteme.jamespot.pro/jlandmap/221156" />}
                                />

                                <Route
                                    path="village"
                                    element={<WorkAdventureIframe slug="public" type="jlandmap" id="111" />}
                                />
                                <Route
                                    path="village/:entry"
                                    element={<WorkAdventureIframe slug="public" type="jlandmap" id="111" />}
                                />
                                <Route
                                    path="coworking"
                                    element={<WorkAdventureIframe slug="public" type="jlandmap" id="2020" />}
                                />
                                <Route
                                    path="amphitheatre"
                                    element={<WorkAdventureIframe slug="public" type="jlandmap" id="40" />}
                                />
                                <Route
                                    path="office/:slug"
                                    element={<WorkAdventureIframe type="staticmap" id="1910" />}
                                />
                                <Route path="summit" element={<Summit />} />
                                <Route path="summit/:entry" element={<Summit />} />
                                <Route path="jday" element={<JDay />} />
                                <Route
                                    path="cafe"
                                    element={<WorkAdventureIframe slug="public" type="jlandmap" id="1686" />}
                                />
                                <Route
                                    path="qg"
                                    element={<WorkAdventureIframe slug="public" type="jlandmap" id="93100" />}
                                />
                                <Route
                                    path="christmas-game"
                                    element={<WorkAdventureIframe slug="public" type="jlandmap" id="2512" />}
                                />
                                {/** end handle nicer URLs and old paths */}
                                <Route path="airspot" element={<AirSpot showPrivate showPublic />} />
                                <Route path="airspot/private" element={<AirSpot showPrivate />} />
                                <Route path="airspot/public" element={<AirSpot showPublic />} />
                                <Route path="map/:slug/:type/:id" element={<WorkAdventureIframe />} />
                            </Route>
                            {/**************************************************
                         *
                         PLEASE REGISTER PUBLIC ROUTES IN public/sitemap.xml
                         *
                         ***************************************************/}
                        </Routes>
                    </ScrollToTop>
                </BrowserRouter>
            </IntlProvider>
        </UserContextProvider>
    );
}

export default App;
