import React from 'react';
import { AdminStatRecord, getAdminStat } from '../utils/api';

/**
 * Alphabetical sorting + set global & website first
 */
function sortPlatforms<T>([slug1]: [string, T], [slug2]: [string, T]) {
    return ['global', 'website'].includes(slug1)
        ? -1
        : ['global', 'website'].includes(slug2)
        ? 1
        : slug1.localeCompare(slug2);
}

function getPlatformLabel(slug: string) {
    if (slug === 'global') {
        return 'Cartes publiques';
    } else if (slug === 'website') {
        return 'Site JLand';
    } else {
        return `Plateforme ${slug}`;
    }
}

export function AdminStats() {
    const [stats, setStats] = React.useState<AdminStatRecord | undefined>(undefined);

    React.useEffect(() => {
        getAdminStat().then((stats) => setStats(stats));
    }, []);

    return (
        <main className="max-width" style={{ padding: '8px' }}>
            <h1>Statistiques globales</h1>
            {stats &&
                Object.entries(stats)
                    .sort(sortPlatforms)
                    .map(([slug, maps]) => (
                        <div key={slug}>
                            <h2>{getPlatformLabel(slug)}</h2>
                            {Object.entries(maps).map(([map, stat]) => (
                                <ul key={map}>
                                    <li>
                                        carte <strong>{map}</strong> : {stat.count} utilisateur(s) en cours
                                        d'utilisation dont {stat.countJitsi} sur Jitsi :
                                        <ul>
                                            {stat.rooms.map((room) => (
                                                <li key={room.room}>
                                                    {room.room && (
                                                        <>
                                                            salle Jitsi <strong>{room.room}</strong> : {room.count}{' '}
                                                            utilisateurs en cours d'utilisation
                                                        </>
                                                    )}
                                                    {!room.room && (
                                                        <>
                                                            <strong>salle commune</strong>: {room.count} utilisateurs en
                                                            cours d'utilisation
                                                        </>
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                            ))}
                        </div>
                    ))}
        </main>
    );
}
