import React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import './href.css';

export function Href({
    className,
    variant = 'dark-blue',
    outline,
    round,
    ...props
}: LinkProps &
    React.RefAttributes<HTMLAnchorElement> & {
        variant?: 'purple' | 'dark-blue' | 'sea-blue' | 'orange';
        outline?: boolean;
        round?: boolean;
    }) {
    const classString = `button-like button-${variant} ${className || ''} ${(outline && 'button-outline') || ''} ${
        (round && 'button-round') || ''
    }`;
    return props.to.toString().startsWith('http') ? (
        <a href={props.to.toString()} className={classString} {...props}>
            {props.children}
        </a>
    ) : (
        <Link className={classString} {...props} />
    );
}
