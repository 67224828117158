import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

export type HomePathType = {
    href: string;
    path: string;
    label: string;
    x: number;
    y: number;
};

export function HomePath({ href, path, label, x, y }: HomePathType) {
    return (
        <Link to={href} className="svg-zone">
            <g className="svg-zone-fill" fill="url(#logo-gradient)">
                <path d={path} stroke="#151e72" strokeWidth="12" />
            </g>
            <text x={x} y={y} fill="#151e72" className="svg-text">
                <FormattedMessage id={`PUBLIC_MAP_${label}_TITLE`} />
            </text>
        </Link>
    );
}
