import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { WorkAdventureIframe } from '../components/WorkAdventureIframe';
import { useIntl } from 'react-intl';
import '../style/summit.css';

import jdayLogo from '../resources/jamespot_Day_logo.png';
import { getMessages } from '../utils/api';

export function JDay() {
    const intl = useIntl();
    const { entry } = useParams<{ entry: string }>();
    const [message, setMessage] = React.useState('');

    const fetchAndSetMessage = () => {
        getMessages('jday').then((data) => {
            setMessage(data.footer ?? '');
        });
    };

    useEffect(() => {
        fetchAndSetMessage();
        const intervale = window.setInterval(fetchAndSetMessage, 10000);
        return () => {
            window.clearInterval(intervale);
        };
    }, []);

    return (
        <>
            <WorkAdventureIframe
                slug="public"
                type="jlandmap"
                id="60623"
                entry={entry ? `#${entry}` : ''}
                label={intl.formatMessage({ id: 'JDAY' })}
                className="summit-iframe"
            />
            <div className="summit-footer-container">
                <div className="summit-footer">
                    <div className="summit__footer__jamespot">
                        <img src={jdayLogo} alt="jamespot summit logo" />
                    </div>
                    <div className="summit__footer__message" id="scroll-container">
                        <p id="scroll-text">{message}</p>
                    </div>
                </div>
            </div>
        </>
    );
}
