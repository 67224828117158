import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import homepage from '../resources/homepage.png';
import '../style/home.css';
import { ImageSection } from '../components/ImageSection';
import firstImage from '../resources/first_paragraph.png';
import secondImage from '../resources/second_paragraph.png';
import thirdImage from '../resources/third_paragraph.png';
import fourthImage from '../resources/fourth_paragraph.png';
import { HomePath } from '../components/HomePath';
import { DiscoverJLandBanner } from '../components/common/DiscoverJLandBanner';
import { Card } from '../components/common/Card';
import { Href } from '../components/button/Href';
import { J_URL } from '../utils/const';
import { PUBLIC_MAPS } from '../utils/constant';

export function Home() {
    const intl = useIntl();
    return (
        <>
            <div className="mongolfiere">
                <header>
                    <h1>
                        <FormattedMessage id="TITLE_COMPLEMENT" />
                    </h1>
                    <p id="subtitle">
                        <FormattedMessage id="DESCRIPTION" />
                    </p>
                    <div style={{ marginBottom: '8px' }} className="display-mobile-only">
                        <Href to="/village">
                            <FormattedMessage id="ENTER_JLAND" />
                        </Href>
                        <Href to={J_URL.LAUNCH_OFFICE} outline>
                            <FormattedMessage id="CREATE_MY_VIRTUAL_OFFICE" />
                        </Href>
                    </div>
                </header>
                <div className="img-wrapper" id="map-jland">
                    <div className="svg-wrapper">
                        <svg viewBox="0 0 4096 2458" xmlns="http://www.w3.org/2000/svg" className="svg-overlay">
                            <defs>
                                <linearGradient id="logo-gradient" x1="50%" y1="0%" x2="50%" y2="100%">
                                    <stop offset="0%" stopColor="#FF000044">
                                        <animate
                                            attributeName="stop-color"
                                            values="#FF000044; #00FF0044; #FF000044"
                                            dur="4s"
                                            repeatCount="indefinite"></animate>
                                    </stop>
                                    <stop offset="100%" stopColor="#00FF0044">
                                        <animate
                                            attributeName="stop-color"
                                            values="#00FF0044; #FF000044; #00FF0044"
                                            dur="4s"
                                            repeatCount="indefinite"></animate>
                                    </stop>
                                </linearGradient>
                            </defs>
                            {PUBLIC_MAPS.map((effect, index) => (
                                <HomePath {...effect} key={index} />
                            ))}
                        </svg>
                    </div>
                    <img src={homepage} alt={intl.formatMessage({ id: 'HOMEPAGE_IMG_ALT' })} />
                </div>
            </div>
            <Card
                title="WHAT_IS_JLAND"
                description="WHAT_IS_JLAND_PARAGRAPH"
                links={[{ label: 'DISCOVER_JLAND', to: '/village' }]}
            />
            <div className="why-jamespot">
                <h2 className="title-sea-blue">
                    <FormattedMessage id="WHY_JLAND" />
                </h2>
                <ImageSection
                    textSide="right"
                    img={{
                        src: firstImage,
                        alt: '',
                        'aria-hidden': 'true',
                    }}
                    title={intl.formatMessage({ id: 'FIRST_PARAGRAPH_TITLE' })}
                    paragraph={intl.formatMessage({ id: 'FIRST_PARAGRAPH_TEXT' })}
                    links={[
                        {
                            to: J_URL.LAUNCH_OFFICE,
                            label: intl.formatMessage({ id: 'CREATE_MY_VIRTUAL_OFFICE' }),
                        },
                    ]}
                />
                <ImageSection
                    textSide="left"
                    img={{
                        src: secondImage,
                        alt: '',
                        'aria-hidden': 'true',
                    }}
                    title={intl.formatMessage({ id: 'SECOND_PARAGRAPH_TITLE' })}
                    paragraph={intl.formatMessage({ id: 'SECOND_PARAGRAPH_TEXT' })}
                    links={[
                        {
                            to: 'village',
                            label: intl.formatMessage({ id: 'DISCOVER_JLAND' }),
                        },
                    ]}
                />
                <DiscoverJLandBanner />
                <ImageSection
                    textSide="right"
                    img={{
                        src: thirdImage,
                        alt: '',
                        'aria-hidden': 'true',
                    }}
                    title={intl.formatMessage({ id: 'THIRD_PARAGRAPH_TITLE' })}
                    paragraph={intl.formatMessage({ id: 'THIRD_PARAGRAPH_TEXT' })}
                    links={[
                        {
                            to: J_URL.LAUNCH_OFFICE,
                            label: intl.formatMessage({ id: 'CREATE_MY_VIRTUAL_OFFICE' }),
                        },
                    ]}
                />
                <ImageSection
                    textSide="left"
                    img={{
                        src: fourthImage,
                        alt: '',
                        'aria-hidden': 'true',
                    }}
                    title={intl.formatMessage({ id: 'FOURTH_PARAGRAPH_TITLE' })}
                    paragraph={intl.formatMessage({ id: 'FOURTH_PARAGRAPH_TEXT' })}
                    links={[
                        {
                            to: 'village',
                            label: intl.formatMessage({ id: 'DISCOVER_JLAND' }),
                        },
                    ]}
                />
            </div>
        </>
    );
}
