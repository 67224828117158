import { Footer } from './Footer';
import { TopBar } from './TopBar';
import '../style/retry.css';

export const Retry = (props: { checkAvailability: () => void; message: string; buttonLabel: string }) => {
    return (
        <>
            <TopBar />
            <main>
                <div className="retry">
                    <p>{props.message}</p>
                    <button onClick={props.checkAvailability}>{props.buttonLabel}</button>
                </div>
            </main>
            <Footer />
        </>
    );
};
