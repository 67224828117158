import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';

export function AdminLayout() {
    const [userContextObject] = React.useContext(UserContext);
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!userContextObject.loading && !userContextObject.user?.isJamespot) {
            navigate('/');
        }
    }, [userContextObject.loading, userContextObject.user, navigate]);

    return <Outlet />;
}
