import React from 'react';
import { J_URL } from '../utils/const';

export function CGU() {
    return (
        <div className="max-width">
            <h1>Termes d'utilisation de Jamespot.land</h1>

            <h2>1) Acceptation des termes</h2>
            <p>
                Jamespot.land (JLand) est un service édité par la société{' '}
                <a href={J_URL.JAMESPOT} target="_blank" rel="noreferrer">
                    Jamespot SAS
                </a>
                .
            </p>
            <p>
                En utilisant et en accédant à JLand, vous (1) acceptez ces Conditions (2) que le service est en phase de
                construction et donc d’évolution permanente (3) déclarez avoir l’âge légal pour utiliser un tel service
                (4) déclarez en avoir un usage professionnel (5) acceptez que les données d’usage de Jamespot Land
                soient utilisées à des fins d’amélioration du produit ou pour vous proposez des usages pertinents (6)
                acceptez les CGS classiques de Jamespot disponibles sur le site Web de Jamespot.
            </p>

            <h2>2) Avis de non-responsabilité et modification des conditions d'utilisation</h2>

            <p>
                Le service est fourni «en l'état" et "selon la disponibilité" et peut contenir des défauts et des bugs
                logiciels. Il vous est conseillé de sauvegarder les données, les biens et le contenu importants afin de
                faire preuve de prudence.
            </p>
            <p>
                Les conditions d’utilisation peuvent être modifiées et republiées sur le site à tout moment. Vous avez
                la responsabilité de connaître ces modifications. Votre utilisation des outils après toute modification
                des conditions constitue votre acceptation des conditions d'utilisation telles que modifiées.
            </p>
            <h2>3) Eligibilité</h2>
            <p>JamespotL.Land est destiné aux seuls professionnels.</p>
            <h2>4) Accès et sécurité du compte</h2>
            <p>
                Vous êtes seul responsable de toutes les activités menées par l'intermédiaire de votre compte, que vous
                autorisiez ou non ces activités. En cas de découverte ou de signalement (par vous ou par quelqu'un
                d'autre) d'une fraude, d'une illégalité ou de toute autre conduite violant le présent accord et liée à
                votre compte, nous pouvons suspendre ou bloquer votre/vos compte(s).
            </p>
            <h2>5) Transactions</h2>
            <p>
                Il est possible de louer des parcelles de terrains, de bureau, des avatars, ou tout autre type d’objet
                de JLand ainsi que des tokens non fongibles ("NFT") depuis l’interface de JLand. Ces transactions
                rentrent dans le cadre définit par les CGS standards de Jamespot.
            </p>
            <h2>6) Droits de propriété</h2>
            <p>
                Tous les titres, la propriété et les droits de propriété intellectuelle relatifs au site et aux outils
                sont la propriété exclusive de Jamespot. Vous reconnaissez et acceptez que le site et les outils
                contiennent des informations exclusives et confidentielles qui sont protégées par les lois sur la
                propriété intellectuelle et autres lois applicables.
            </p>
            <p>
                Vous acceptez de ne pas copier, modifier, louer, prêter, vendre, distribuer, exécuter, afficher ou créer
                des œuvres dérivées basées sur le site et les outils, en tout ou en partie. La propriété exclusive de
                Jamespot comprend tous les éléments du site et des outils, ainsi que tous les droits de propriété
                intellectuelle y afférents. Les interfaces visuelles, les graphiques (y compris, mais sans s'y limiter,
                toutes les illustrations et tous les dessins associés aux outils), la conception, les systèmes, les
                méthodes, les informations, le code informatique, les logiciels, l'aspect et la convivialité,
                l'organisation, la compilation du contenu, le code, les données et tous les autres éléments du site et
                des outils (à l'exclusion du contenu soumis par les utilisateurs) sont la propriété de Jamespot.
                L'utilisation du site et des outils ne vous confère aucun droit de propriété ni aucun autre droit à
                l'égard du contenu, du code, des données ou de tout autre matériel auquel vous pouvez accéder sur le
                site et les outils ou par leur intermédiaire.
            </p>
            <h2>7) Résiliation et suspension</h2>
            <p>
                Vous pouvez résilier votre compte à tout moment en annulant et en interrompant votre accès aux outils et
                leur utilisation. Jamespot peut décider de résilier ou de suspendre tout ou partie du site et des outils
                et votre accès au site et aux outils immédiatement, sans préavis ni responsabilité. Vous acceptez que
                Jamespot, à sa seule discrétion et pour toute raison ou sans raison, puisse résilier les présentes
                conditions et suspendre votre/vos compte(s) pour les outils. Vous convenez que toute suspension de votre
                accès au site ou aux outils peut se faire sans préavis, et que Jamespot (ainsi que ses dirigeants et
                employés) ne seront pas responsables envers vous ou un tiers pour une telle suspension.
            </p>
            <h2>8) General Information</h2>
            <p>
                Les utilisateurs ayant des questions, des plaintes ou des réclamations concernant le site ou les outils
                peuvent nous contacter en utilisant les informations de contact suivantes : info (at) jamespot.com
            </p>
        </div>
    );
}
