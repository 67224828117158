export const KEY_CODES = {
    // WARNING maj ?
    // tab: "tab",
    // enter: "enter",
    // escape: "escape",
    // space: "space",
    // pageUp: "pageUp",
    // pageDown: "pageDown",
    // end: "end",
    // home: "home",
    ARROW_LEFT: 'ArrowLeft',
    ARROW_UP: 'ArrowUp',
    ARROW_RIGHT: 'ArrowRight',
    ARROW_DOWN: 'ArrowDown',
    KEY_W: 'KeyW',
    KEY_A: 'KeyA',
    KEY_S: 'KeyS',
    KEY_D: 'KeyD',
};

export const NAVIGATION_KEYS = [
    KEY_CODES.ARROW_LEFT,
    KEY_CODES.ARROW_UP,
    KEY_CODES.ARROW_RIGHT,
    KEY_CODES.ARROW_DOWN,
    KEY_CODES.KEY_W,
    KEY_CODES.KEY_A,
    KEY_CODES.KEY_S,
    KEY_CODES.KEY_D,
];
