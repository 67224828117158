import { useLocation, useNavigate, useParams } from 'react-router-dom';
import React, { useRef } from 'react';
import { WAMessage } from '../utils/types';
import { NAVIGATION_KEYS } from '../utils/keyboard';
import '../style/iframe.css';
import { socket } from '../utils/socket';
import { getWAUri } from '../utils/workadventure';

export type WAIframeProps = {
    slug?: string;
    type: string;
    id: string;
    entry?: string;
    label?: string;
    className?: string;
};

export const WorkAdventureIframe: React.FC<WAIframeProps | {}> = function WorkAdventureIframe(props) {
    const {
        slug: slugParam,
        type: typeParam,
        id: idParam,
        entry: entryParam,
    } = useParams<{ slug: string; type: string; id: string; entry: string }>();
    const location = useLocation();

    const slug = 'slug' in props && props.slug ? props.slug : slugParam;
    const type = 'type' in props && props.type ? props.type : typeParam;
    const id = 'id' in props && props.id ? props.id : idParam;
    const entry = 'entry' in props && props.entry ? `#${props.entry}` : entryParam ? `#${entryParam}` : location.hash;

    const navigate = useNavigate();
    const iframe = useRef<HTMLIFrameElement>(null);

    const KeyboardIframeTrap = (event: KeyboardEvent) => {
        if (NAVIGATION_KEYS.includes(event.code)) {
            iframe?.current?.contentWindow?.focus();
        }
    };

    React.useEffect(() => {
        window.addEventListener('keydown', KeyboardIframeTrap);

        return () => {
            window.removeEventListener('keydown', KeyboardIframeTrap);
        };
    }, []);

    const onMessage = React.useCallback(
        (event: MessageEvent<WAMessage>) => {
            // TODO: check event source before managing it
            if (event?.data?.type && event?.data?.value) {
                if (event?.data?.action === 'goto') {
                    switch (event.data.type) {
                        case 'external':
                            window.location.href = event.data.value;
                            break;
                        case 'internal':
                            navigate(event.data.value);
                            break;
                        case 'platform':
                            if (slug) {
                                const path = event.data.value?.startsWith('/')
                                    ? event.data.value
                                    : `/${event.data.value}`;
                                window.location.href = `https://${slug}${path}`;
                            }
                            break;
                        default:
                            break;
                    }
                } else if (event?.data?.action === 'visio') {
                    socket.emit('visio', {
                        type: event?.data?.type,
                        value: event?.data?.value,
                        map: window.location.pathname,
                    });
                }
            }
        },
        [navigate, slug],
    );

    React.useEffect(() => {
        window.addEventListener('message', onMessage);

        return () => {
            window.removeEventListener('message', onMessage);
        };
    }, [onMessage]);

    if (!slug || !type || !id) {
        return <></>;
    }

    return (
        <iframe
            className={'className' in props && props?.className ? props.className : 'full-screen'}
            ref={iframe}
            src={getWAUri(slug || '', type || '', id || '', entry)}
            allow="camera;microphone;display-capture"
            title={'label' in props && props?.label ? props.label : `Jland map`}
        />
    );
};
