import { User } from '../context/UserContext';
import type { ServerMaps } from './types';

export const DISCONNECT_CONTENT =
    '<div class="container"><h1>Sign-out Success</h1><p>Your sign-out  was successful.</p></div>';

export const CONNEXION_CONTENT =
    '<div style="height:300px; background: url(img/connected.gif) no-repeat center #262626;"></div>';

export const CONNEXION_ADDRESS = '/jland/connexion';
export const LOGOUT_ADDRESS = '/jland/logout';
export const AVAILABILITY_ADDRESS = '/jland/availability';
export const PLATFORMS_ADDRESS = '/jland/platforms';
export const ME_ADDRESS = '/jland/me';
export const ROOM_ACCESS_ADDRESS = '/jland/api/room/access';
export const ADMIN_STATS = '/jland/stats/all';

export async function availability(route: string): Promise<{ available: boolean }> {
    return fetch(AVAILABILITY_ADDRESS, {
        method: 'POST',
        body: JSON.stringify({ route }),
        headers: new Headers({ 'Content-Type': 'application/json' }),
    }).then((response) => response.json());
}

export async function checkAccessWA(playUrl: string, userId = ''): Promise<{ allowed: boolean }> {
    const queryUrl = new URL(ROOM_ACCESS_ADDRESS, window.location.href);
    queryUrl.searchParams.append('userIdentifier', userId);
    queryUrl.searchParams.append('playUri', playUrl);

    return fetch(queryUrl.toString()).then(async (resp) => {
        if (resp.ok) {
            return resp.json();
        }
        throw await resp.json();
    });
}

export async function getUser(): Promise<User> {
    return fetch(ME_ADDRESS).then((response) => response.json());
}

export async function getMyPlatforms(): Promise<ServerMaps[]> {
    return fetch(PLATFORMS_ADDRESS).then((response) => response.json());
}

export async function sessionAction(popupAddress: string, endPopupContent: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        try {
            const style = 'menubar=no,status=no,scrollbars=no,menubar=no,width=400,height=350';
            const popup = window.open(popupAddress, 'My Jamespot', style);
            const intervalPromise = checkState(popup, endPopupContent);
            intervalPromise
                .then(() => {
                    resolve();
                })
                .catch((error) => {
                    reject(error);
                });
        } catch (error) {
            reject(error);
        }
    });
}

export const checkState = async (popup: Window | null, content: string): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
        const intervalId = setInterval(() => {
            if (!popup || popup.closed) {
                clearInterval(intervalId);
                reject();
                return;
            }
            let popupContent = '';
            try {
                popupContent = popup.document?.body?.innerHTML;
            } catch (error) {
                /**
                 * if popup is redirected (OIDC login or logout), it is in another domain and popup.document will throw:
                 * DOMException: Permission denied to access property "document" on cross-origin object
                 * we can ignore this exception
                 */
            }
            if (popupContent === content) {
                clearInterval(intervalId);
                popup.close();
                resolve();
            }
        }, 1000);
    });
};

export type AdminStat = {
    slug: string;
    map: string;
    count: number;
    countJitsi: number;
    rooms: Array<{
        room: string | undefined;
        count: number;
    }>;
};

export type AdminStatRecord = Record<string, Record<string, AdminStat>>;

export async function getAdminStat(): Promise<AdminStatRecord> {
    return fetch(ADMIN_STATS).then((response) => response.json());
}

export type Messages = {
    footer?: string;
};

export async function getMessages(channel: 'jday' | 'summit'): Promise<Messages> {
    return window.fetch(`/jland/${channel}/message`).then((resp) => resp.json());
}

export async function setMessages(messages: Messages, channel: 'jday' | 'summit'): Promise<void> {
    return window
        .fetch(`/jland/${channel}/message`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ messages: messages }),
        })
        .then((resp) => resp.json());
}
