import React from 'react';
import '../style/who-we-are.css';
import { Href } from '../components/button/Href';
import { Img } from '../components/img/Img';
import ImgHomePageRightHandPart from '../resources/map-right-hand-part.png';
import ImgJamespotMembers from '../resources/jamespot_members.png';
import ImgJamespotSolution from '../resources/jamespot_solution.png';
import ImgJamespotCollab from '../resources/jamespot_collab.png';
import { DiscoverJLandBanner } from '../components/common/DiscoverJLandBanner';
import { Card } from '../components/common/Card';
import { CardImg } from '../components/common/CardImg';
import { J_URL } from '../utils/const';

const DATA = [
    { data: '17', description: 'ans d’existence et d’innovation' },
    { data: '40', description: 'collaborateurs passionnés' },
    { data: '400k', description: 'utilisateurs à travers le monde' },
    { data: '400', description: 'clients de toutes tailles et de tous secteurs' },
    { data: '12', description: 'récompenses pour l’accompagnement projets' },
];

export function WhoWeAre() {
    return (
        <div className="description-page">
            <CardImg
                title="Qui sommes-nous ?"
                subtitle="Jamespot.Land : Le métavers des professionnels !"
                links={[
                    {
                        to: '/village',
                        label: 'Découvrir Jamespot Land ✨',
                    },
                ]}
                img={ImgHomePageRightHandPart}
                alt="Partie droite du village comprenant les bureaux et la zone de détente : la plage"
            />

            <div className="max-width card">
                <div className="flex">
                    {DATA.map(({ data, description }, index) => (
                        <div key={index}>
                            <div className="data">
                                <p className="number">{data}</p>
                            </div>
                            <div className="data">
                                <p>{description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div className="max-width grid grid-reverse margin-h">
                <div>
                    <h2>La société Jamespot</h2>
                    <p>
                        Depuis sa création en juillet 2005, Jamespot édite des solutions collaboratives à destination
                        des organisations de toute taille et de tout secteur avec comme objectif de replacer l’humain au
                        cœur des systèmes d’information.
                    </p>
                    <p>
                        Aujourd’hui leader du marché des solutions collaboratives françaises dans le cloud, Jamespot
                        édite une solution éponyme conçue pour renforcer la collaboration transverse et la communication
                        des entreprises.
                    </p>
                    <p>
                        <Href to={J_URL.JAMESPOT}>Je découvre Jamespot ✨</Href>
                    </p>
                </div>
                <Img src={ImgJamespotMembers} height="300" alt="Vignettes des visages de l'équipe Jamespot" />
            </div>

            <div className="max-width grid margin-h">
                <div>
                    <h2>La solution Jamespot</h2>
                    <p>
                        La solution Jamespot est une Digital Workplace souveraine et sécurisée, unique par sa modularité
                        et ses capacités de personnalisation. Elle se déploie sous la forme de 4 packs applicatifs
                        complémentaires selon les besoins et objectifs de ses utilisateurs :
                    </p>
                    <ul>
                        <li>
                            <strong>
                                <a href={J_URL.JAMESPOT_OPEN_AGORA}>Open Agora</a>
                            </strong>{' '}
                            | Le réseau social d’entreprise qui regroupe vos collaborateurs autour des informations
                            métiers et favorise les échanges transverses.
                        </li>
                        <li>
                            <strong>
                                <a href={J_URL.JAMESPOT_FAST_INTRANET}>Fast Intranet</a>
                            </strong>{' '}
                            | L’intranet collaboratif pour renforcer votre communication interne et structurer l’accès à
                            votre système d’information.
                        </li>
                        <li>
                            <strong>
                                <a href={J_URL.JAMESPOT_DIAPAZONE}>Diapazone</a>
                            </strong>{' '}
                            | L’outil d’animation de réunions pour des échanges collectifs organisés, dynamiques et
                            participatifs.
                        </li>
                        <li>
                            <strong>
                                <a href={J_URL.JAMESPOT_WEDOC}>We Doc</a>
                            </strong>{' '}
                            | La solution d’édition collaborative pour des documents mis à jour et accessibles à tous.
                        </li>
                    </ul>
                    <p>
                        <Href to={J_URL.JAMESPOT}>Je découvre Jamespot ✨</Href>
                    </p>
                </div>
                <Img src={ImgJamespotSolution} height="400" alt="Ecrans de la solution Jamespot" />
            </div>

            <div className="max-width grid grid-reverse margin-h">
                <div>
                    <h2>Les solutions collaboratives</h2>
                    <p>
                        En plus de ses 4 produits, la solution Jamespot dispose d’un parc applicatif de plus d’une
                        centaine d’applications métiers pour la collaboration, la communication ou encore la gestion de
                        projets.
                    </p>
                    <p>
                        Chaque application est activable à la volée et permet à chaque organisation d’enrichir ses
                        usages sur la durée selon les besoins métiers de ses collaborateurs.
                    </p>
                    <p>
                        <Href to={J_URL.JAMESPOT}>Je découvre Jamespot ✨</Href>
                    </p>
                </div>
                <p>
                    <Img src={ImgJamespotCollab} height="350" alt="Vignettes des visages de l'équipe Jamespot" />
                </p>
            </div>

            <Card
                title="Et si vous passiez à la vitesse Jamespot ?"
                description="Démarrez votre plateforme de test et embarquez votre équipe sur Jamespot en moins de 5 minutes 🚀"
                links={[{ label: 'CREATE_MY_PLATFORM', to: J_URL.LAUNCH_PLATFORM }]}
            />

            <DiscoverJLandBanner />
        </div>
    );
}
