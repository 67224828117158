import { FormattedMessage } from 'react-intl';
import './card.css';
import { Href } from '../button/Href';

export function Card({
    title,
    description,
    links,
}: {
    title?: string;
    description: string;
    links?: Array<{ label: string; to: string }>;
}) {
    return (
        <div className="card">
            <div className="orange-dot-wrapper">
                <div className="orange-dot"></div>
            </div>
            {title && (
                <h2>
                    <FormattedMessage id={title} />
                </h2>
            )}
            <p>
                <FormattedMessage id={description} />
            </p>
            {links && links.length && (
                <div>
                    {links?.map(({ label, to }) => (
                        <Href to={to} key={to}>
                            <FormattedMessage id={label} />
                        </Href>
                    ))}
                </div>
            )}
        </div>
    );
}
