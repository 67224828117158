import React from 'react';
import { getMessages, setMessages } from '../utils/api';

export type AdminMessageProps = {
    target: 'summit' | 'jday';
};

export const AdminMessage: React.FC<AdminMessageProps> = function AdminMessage({ target }) {
    const [message, setMessage] = React.useState<string>('');
    const [loading, setIsLoading] = React.useState<boolean>(true);

    const loadMessage = React.useCallback(() => {
        getMessages(target).then((messages) => {
            setIsLoading(false);
            setMessage(messages?.footer ?? '');
        });
    }, [target]);

    React.useEffect(() => {
        loadMessage();
    }, [loadMessage]);

    const sendMessage: React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        setIsLoading(true);
        setMessages({ footer: message }, target).then(() => {
            loadMessage();
        });
    };

    return (
        <main className="max-width" style={{ padding: '8px' }}>
            <h1>Envoyer un message au {target}</h1>
            <div>
                {loading ? (
                    <p>Traitement en cours...</p>
                ) : (
                    <form onSubmit={sendMessage}>
                        <div style={{ display: 'flex' }}>
                            <input
                                style={{ flexGrow: 1 }}
                                type="text"
                                value={message}
                                onChange={(event) => setMessage(event.target.value)}
                            />
                            <input type="submit" value="Envoyer un nouveau message" />
                        </div>
                    </form>
                )}
            </div>
        </main>
    );
};
