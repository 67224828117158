import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserContext } from '../context/UserContext';
import { CONNEXION_ADDRESS, CONNEXION_CONTENT, getUser, sessionAction } from '../utils/api';
import '../style/Connect.css';

export function Connect() {
    const setUserContextObject = React.useContext(UserContext)[1];
    const [connectionError, setConnectionError] = React.useState<boolean>(false);

    const retry = () => {
        setUserContextObject((currentValue) => {
            return {
                ...currentValue,
                loading: true,
            };
        });
        setConnectionError(false);
        setTimeout(() => {
            handleConnectClick();
        }, 0);
    };

    const refreshUser = React.useCallback(() => {
        getUser().then((user) => {
            if (user?.id) {
                setUserContextObject({
                    user: user,
                    loading: false,
                });
            } else {
                setUserContextObject({
                    user: undefined,
                    loading: false,
                });
            }
        });
    }, [setUserContextObject]);

    const handleConnectClick = React.useCallback(() => {
        sessionAction(CONNEXION_ADDRESS, CONNEXION_CONTENT)
            .then(refreshUser)
            .catch(() => {
                /** TODO: Show a toast stating an error occured ? */
                setUserContextObject((currentValue) => {
                    return {
                        ...currentValue,
                        loading: false,
                    };
                });
                setConnectionError(true);
            });
        setUserContextObject((currentValue) => {
            return {
                ...currentValue,
                loading: true,
            };
        });
    }, [refreshUser, setUserContextObject]);

    return (
        <div className="connect">
            {!connectionError && (
                <p>
                    <FormattedMessage id="AIRSPOT_CONNECT_TEXT_1" /> <br />
                    <FormattedMessage id="AIRSPOT_CONNECT_TEXT_2" /> <br />
                    <FormattedMessage id="AIRSPOT_CONNECT_TEXT_3" /> <br />
                    <br />
                    <button onClick={handleConnectClick}>
                        <FormattedMessage id="BUTTON_LOGIN" />
                    </button>
                </p>
            )}
            {connectionError && (
                <p>
                    <FormattedMessage id="CONNECT_ERROR" /> <br />
                    <br />
                    <button onClick={retry}>
                        <FormattedMessage id="RETRY" />
                    </button>
                </p>
            )}
        </div>
    );
}
