import React from 'react';

export function CookieNotice() {
    return (
        <div className="max-width">
            <h1>Gestion des cookies</h1>
            <h2>Politique de gestion des cookies du site "www.jamespot.land"</h2>
            <h3>Avant-propos : définition des cookies</h3>

            <p>
                Un "cookie" est un fichier texte susceptible d’être déposé sur votre terminal à l’occasion de la visite
                de notre Site, "www.jamespot.land". Il a pour but de collecter des informations anonymes relatives à
                votre navigation et de vous adresser des contenus adaptés à votre terminal ou à vos centres d’intérêts.
                Seul l’émetteur d’un cookie est susceptible de lire ou de modifier des informations qui y sont
                contenues.
            </p>
            <p>
                Lors de la consultation de notre Site, des cookies sont susceptibles d’être déposés sur votre terminal
                (ordinateur, mobile, tablette).
            </p>
            <p>
                La présente Politique de Cookies précise les différentes finalités liées à ces Cookies et explique votre
                démarche à suivre pour les paramétrer.
            </p>

            <h3>1- Les différents Cookies présents sur le Site</h3>

            <h4>Les Cookies tiers de mesure d’audience :</h4>

            <p>
                Ces Cookies sont utilisés afin d’évaluer l’utilisation du Site, en collectant des informations sur votre
                navigation. Ces informations sont collectées afin d’effectuer des mesures d’audience, de statistiques,
                dans le but d’améliorer la qualité des services fournis par le Site.
            </p>
            <p>
                Pour plus d’information sur ces cookies, nous vous invitons à prendre connaissance de leurs conditions
                d’utilisation sur les sites de ces Cookies tiers :
            </p>

            <ul>
                <li>
                    Google Analytics:{' '}
                    <a href="https://support.google.com/analytics/answer/7108387?hl=fr">Conditions d’utilisation</a> -{' '}
                    <a href="https://tools.google.com/dlpage/gaoptout">Désactiver les cookiers</a>
                </li>
                <li>
                    Matomo: <a href="https://fr.matomo.org/privacy-policy/">Conditions d’utilisation</a> -{' '}
                    <a href="https://fr.matomo.org/privacy-policy/">Désactiver les cookiers</a>
                </li>
            </ul>

            <h4>Les cookies de fonctionnement :</h4>

            <p>Ces Cookies sont apposés sur le Site pour gérer la connexion. Ils ne peuvent pas être désactivés.</p>

            <h3>2- Durée de vie des Cookies et destinataires</h3>

            <p>
                Les cookies installés lors de la navigation sur le site expirent automatiquement à l’issue d’une durée
                de 13 mois sans avoir consulté le site.
            </p>
            <p>
                Dans le cadre de l’utilisation des cookies, des données sont susceptibles d'être transférées hors de
                l'Union Européenne à des prestataires de Jamespot dans le cadre de la gestion du Site, sous réserve que
                le pays concerné bénéficie d'une décision d'adéquation, ou à tout le moins, que ce transfert soit
                encadré par des garanties appropriées.
            </p>
            <p>Sont susceptibles d'être concernés par ce transfert hors de l'UE les prestataires tiers suivants :</p>

            <ul>
                <li>Google</li>
                <li>Microsoft</li>
            </ul>

            <h3>3- Comment paramétrer les Cookies ?</h3>

            <p>
                Vous êtes libre d’accepter ou de refuser, à tout moment, tout ou partie des Cookies soumis à votre
                consentement préalable via notre gestionnaire de Cookies.
            </p>
            <p>
                Par ailleurs, la plupart des navigateurs sont configurés par défaut de façon à ce que le dépôt de
                cookies soit autorisé. Votre navigateur vous donne la possibilité de modifier ces paramètres standards,
                mais également de supprimer les cookies existants et déposés sur votre équipement terminal, ou de vous
                signaler si de nouveaux cookies sont susceptibles d’être déposés sur votre terminal. Toutefois, si vous
                choisissez de désactiver les Cookies via votre navigateur, il est possible que vous ne puissiez plus
                bénéficier de toutes les fonctionnalités que nous proposons à travers le Site.
            </p>
            <p>
                Pour plus d’informations sur le paramétrage des Cookies, vous pouvez notamment consulter les sites
                suivants :
            </p>

            <ul>
                <li>
                    Le <a href="http://www.cnil.fr/vos-libertes/vos-traces/les-cookies/">site internet de la CNIL</a>
                </li>
                <li>
                    <a href="http://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies">
                        Internet Explorer
                    </a>
                </li>
                <li>
                    <a href="http://support.apple.com/kb/ht1677?viewlocale=fr_FR">Safari</a>
                </li>
                <li>
                    <a href="https://support.google.com/chrome/answer/95647?hl=fr">Chrome</a>
                </li>
                <li>
                    <a href="https://support.mozilla.org/fr/kb/activer-desactiver-cookies">Firefox</a>
                </li>
                <li>
                    <a href="http://help.opera.com/Windows/10.20/fr/cookies.html">Opera</a>
                </li>
            </ul>
        </div>
    );
}
