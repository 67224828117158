import { FormattedMessage, useIntl } from 'react-intl';
import { Menu } from './Menu';
import '../style/topbar.css';
import logo from '../resources/Logo_JLand.png';
import { Link } from 'react-router-dom';
import { Href } from './button/Href';
import { J_URL } from '../utils/const';

export const TopBar = () => {
    const intl = useIntl();
    return (
        <div className="top-bar-wrapper">
            <div className="top-bar">
                <Link to="/" className="logo">
                    <img src={logo} alt={intl.formatMessage({ id: 'JLAND_LOGO_ALT' })} />
                </Link>
                <div className="spacer"></div>
                <Href to="/village">
                    <FormattedMessage id="ENTER_JLAND" />
                </Href>
                <Href to={J_URL.LAUNCH_OFFICE} outline>
                    <FormattedMessage id="CREATE_MY_VIRTUAL_OFFICE" />
                </Href>
                <Menu />
            </div>
        </div>
    );
};
