import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import menuIcon from '../resources/menu.svg';
import '../style/menu.css';
import { UserContext } from '../context/UserContext';
import { ClickAway } from './ClickAway';
import {
    CONNEXION_ADDRESS,
    CONNEXION_CONTENT,
    DISCONNECT_CONTENT,
    getUser,
    LOGOUT_ADDRESS,
    sessionAction,
} from '../utils/api';

export function Menu() {
    const intl = useIntl();

    const [userContextObject, setUserContextObject] = React.useContext(UserContext);
    const [open, setOpen] = React.useState(false);

    const refreshUser = React.useCallback(() => {
        getUser().then((user) => {
            if (user?.id) {
                setUserContextObject({
                    user: user,
                    loading: false,
                });
            } else {
                setUserContextObject({
                    user: undefined,
                    loading: false,
                });
            }
        });
    }, [setUserContextObject]);

    const handleDisconnectClick = React.useCallback(() => {
        sessionAction(LOGOUT_ADDRESS, DISCONNECT_CONTENT).then(refreshUser).catch(refreshUser);
        setUserContextObject((currentValue) => {
            return {
                ...currentValue,
                loading: true,
            };
        });
    }, [refreshUser, setUserContextObject]);

    const handleConnectClick = React.useCallback(() => {
        sessionAction(CONNEXION_ADDRESS, CONNEXION_CONTENT)
            .then(refreshUser)
            .catch(() => {
                /** TODO: Show a toast stating an error occured ? */
                setUserContextObject((currentValue) => {
                    return {
                        ...currentValue,
                        loading: false,
                    };
                });
            });
        setUserContextObject((currentValue) => {
            return {
                ...currentValue,
                loading: true,
            };
        });
    }, [refreshUser, setUserContextObject]);

    return (
        <>
            <ClickAway onClickAway={() => setOpen(false)}>
                <div className="menu_wrapper">
                    <button id="menu-button" aria-labelledby="menu-icon" onClick={() => setOpen(!open)}>
                        <img id="menu-icon" src={menuIcon} alt={intl.formatMessage({ id: 'BUTTON_MENU' })} />
                    </button>
                    {open && (
                        <nav aria-hidden={!open && 'true'}>
                            {userContextObject.user ? (
                                <button
                                    onClick={() => {
                                        handleDisconnectClick();
                                        setOpen(false);
                                    }}>
                                    <FormattedMessage id="BUTTON_LOGOUT" />
                                </button>
                            ) : (
                                <button
                                    onClick={() => {
                                        handleConnectClick();
                                        setOpen(false);
                                    }}>
                                    <FormattedMessage id="BUTTON_LOGIN" />
                                </button>
                            )}
                            <Link to="/airspot">
                                <FormattedMessage id="ACCESS_AIRSPOT" />
                            </Link>
                            <Link to="/world-of-jland">
                                <FormattedMessage id="LINK_WORLD_OF_JLAND" />
                            </Link>
                            <Link to="/offices">
                                <FormattedMessage id="LINK_OFFICES" />
                            </Link>
                            <Link to="/who-we-are">
                                <FormattedMessage id="LINK_WHO_WE_ARE" />
                            </Link>
                            <Link to="/cgu">
                                <FormattedMessage id="LINK_CGU" />
                            </Link>
                        </nav>
                    )}
                </div>
            </ClickAway>
        </>
    );
}
