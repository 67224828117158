export const LANG = {
    fr: {
        TITLE: 'Jamespot.land ',
        TITLE_COMPLEMENT: 'Le métavers des professionnels',
        DESCRIPTION: "Où allez vous travailler aujourd'hui ?",
        ENTER_JLAND: 'Rentrer dans JLand ✨',
        HOMEPAGE_IMG_ALT: 'Carte de Jamespot Land',
        JLAND_LOGO_ALT: 'Logo Jamespot Land',
        JAMESPOT_LOGO_ALT: 'Logo Jamespot',
        CONNECT_ERROR: 'Il y a eu un problème lors du processus de connexion, voulez-vous réessayer ?',
        CONNECT_WAITING: 'En attente de connexion',
        COWORKING: 'Coworking',
        CAFE: 'Café',
        MY_PLATFORMS: 'Mes plateformes:',
        RETRY: 'Réessayer',
        VILLAGE: 'Village',
        GO_TO_VILLAGE: 'Aller au village',
        GO_TO_HOME: "Retour à l'accueil",

        // What is Jamespot Land
        WHAT_IS_JLAND: 'C’est quoi Jamespot Land ?',
        WHAT_IS_JLAND_PARAGRAPH:
            'Jamespot Land est le premier métavers entièrement pensé et conçu par et pour les professionnels. Embarquez et engagez vos collaborateurs dans une expérience numérique unique pour leur permettre de rester connectés et informés où qu’ils se trouvent.',
        DISCOVER_JLAND: 'Découvrir Jamespot Land ✨',
        CREATE_MY_PLATFORM: 'Tester Jamespot 🚀',
        DISCOVER_OFFERS: 'Découvrir les offres Jamespot 🌠',

        // Why chosing Jamespot.land
        WHY_JLAND: 'Pourquoi choisir Jamespot.Land ?',

        // §1
        FIRST_PARAGRAPH_TITLE: 'Vos propres locaux numériques',
        FIRST_PARAGRAPH_TEXT:
            'Regroupez tous vos collaborateurs dans une carte entièrement dédiée à votre organisation et repoussez les frontières de Jamespot Land en faisant votre choix parmi nos différents templates de cartes.',
        CREATE_MY_VIRTUAL_OFFICE: 'Créer mes locaux 🚀',

        //§2
        SECOND_PARAGRAPH_TITLE: 'Explorez de nouvelles méthodes de collaboration',
        SECOND_PARAGRAPH_TEXT:
            'Embarquez votre organisation dans le tout premier métavers professionnel français ! Décloisonnez et engagez vos collaborateurs dans un outil de collaboration unique où réunions dynamiques et échanges informels sont à nouveau à l’ordre du jour.',

        // §3
        THIRD_PARAGRAPH_TITLE: 'Remettez votre culture d’entreprise au centre du jeu',
        THIRD_PARAGRAPH_TEXT:
            'Mettez fin à l’isolement de vos collaborateurs et redonnez la part belle au collectif ! Renforcez la culture de votre organisation en regroupant tous vos collaborateurs dans un lieu de travail où collaboration et temps réel ne font plus qu’un.',

        // §4
        FOURTH_PARAGRAPH_TITLE: 'Le lieu de rencontre des professionnels',
        FOURTH_PARAGRAPH_TEXT:
            'Élargissez votre réseau grâce à Jamespot Land ! Faites la rencontre d’autres organisations et échangez à la volée avec les professionnels de différents secteurs dans les espaces publics de Jamespot Land.',

        // layout
        BUTTON_MENU: 'Menu',
        BUTTON_LOGIN: 'Se connecter',
        BUTTON_LOGOUT: 'Se déconnecter',
        LINK_WORLD_OF_JLAND: 'Le monde de Jamespot Land',
        LINK_OFFICES: 'Les espaces publics et privés',
        LINK_WHO_WE_ARE: 'Qui sommes-nous ?',
        ACCESS_AIRSPOT: 'Accéder au AirSpot',
        LINK_CGU: "Conditions générales d'utilisation",
        MADE_WITH_LOVE_BY: 'Made with ❤️ by ',

        // Airspot
        AIRSPOT_TITLE: 'Bienvenue dans le AirSpot',
        PUBLIC_MAP_VILLAGE_TITLE: 'Le village ',
        PUBLIC_MAP_VILLAGE_DESCRIPTION: 'Profitez du grand air et rencontrez du monde',
        PUBLIC_MAP_COWORKING_TITLE: 'Co-Working',
        PUBLIC_MAP_COWORKING_DESCRIPTION: 'Et retrouvez-y vos partenaires de travail ?',
        PUBLIC_MAP_COFFEE_TITLE: 'Le café',
        PUBLIC_MAP_COFFEE_DESCRIPTION: 'Pour favoriser la concentration ou l’échange',
        PUBLIC_MAP_SEA_TITLE: 'La mer',
        PUBLIC_MAP_SEA_DESCRIPTION: 'On prend une pause ?',
        PUBLIC_MAP_AMPHITHEATER_TITLE: 'L’amphithéâtre',
        PUBLIC_MAP_AMPHITHEATER_DESCRIPTION: 'Animez une conférence ou assistez y',
        PUBLIC_MAP_CLIENT_TITLE: 'Mes bureaux',
        PUBLIC_MAP_QG_TITLE: 'Quartier général',
        PUBLIC_MAP_QG_DESCRIPTION: 'Venez découvrir Jamespot et ses collaborateurs',
        PRIVATE_MAP_MY_PLATFORM: 'Ma plateforme',
        PRIVATE_MAP_MY_OFFICE: 'Mes bureaux virtuels',
        AIRSPOT_CONNECT_TEXT_1: "Utilisateur d'une plateforme Jamespot ?",
        AIRSPOT_CONNECT_TEXT_2: 'Vous pouvez vous connecter pour accéder aux espaces privés de vos plateformes.',
        AIRSPOT_CONNECT_TEXT_3: "(Une popup devrait s'ouvrir pour la connexion)",

        // page coming later
        COMING_LATER:
            "Les dieux du metarvers n'ont pas encore créé cette partie de l'univers. N'hésitez pas à revenir plus tard !",
        // if modified, change default meta tag in index.html
        META_TITLE: 'Jamespot Land | Le métavers professionnel signé Jamespot',
        META_DESCRIPTION:
            'Découvrez Jamespot Land, le premier métavers pour professionnels. Un environnement numérique innovant pour optimiser votre communication interne.',

        // Full
        JLAND_FULL:
            "Victime de son succès, JLand n'est pas accessible. N'hésitez pas à rafraichir dans quelques minutes",
        MAP_FORBIDDEN:
            "Vous n'êtes pas autorisé à accéder à cet espace. Votre compte n'est peut-être pas encore actif, vous pouvez réessayer dans quelques minutes. Si le problème persiste, contactez votre administrateur ou le support.",
        REFRESH: 'Rafraîchir',
    },
    en: {
        TITLE: 'Jamespot.land',
        TITLE_COMPLEMENT: 'The metaverse for professionals',
        DESCRIPTION: 'Where would you like to work today ?',
        ENTER_JLAND: 'Enter into JLand ✨',
        CONNECT_ERROR: 'There was an error during the connection process, would you like to retry?',
        HOMEPAGE_IMG_ALT: 'Jamespot Land map',
        JLAND_LOGO_ALT: 'Logo Jamespot Land',
        JAMESPOT_LOGO_ALT: 'Logo Jamespot',
        CONNECT_WAITING: 'Waiting for connection',
        COWORKING: 'Coworking',
        CAFE: 'Coffee',
        QG: 'Jamespot HQ',
        MY_PLATFORMS: 'My platforms:',
        RETRY: 'Retry',
        VILLAGE: 'Village',
        GO_TO_VILLAGE: 'Go to the village',
        GO_TO_HOME: 'Go back to homepage',

        // layout
        BUTTON_MENU: 'Menu',
        BUTTON_LOGIN: 'Login',
        BUTTON_LOGOUT: 'Logout',
        LINK_WHAT_IS_IT: 'What is JLand?',
        LINK_WHY_YOU_SHOULD_USE_IT: 'Why you should use it?',
        LINK_CGU: 'Terms of use',
        MADE_WITH_LOVE_BY: 'Made with ❤️ by ',

        // Airspot
        AIRSPOT_TITLE: 'Welcome to the AirSpot',
        PUBLIC_MAP_VILLAGE_TITLE: 'The village ',
        PUBLIC_MAP_VILLAGE_DESCRIPTION: 'Enjoy the fresh air and meet people',
        PUBLIC_MAP_COWORKING_TITLE: 'Co-Working',
        PUBLIC_MAP_COWORKING_DESCRIPTION: 'And meet your work partners there?',
        PUBLIC_MAP_COFFEE_TITLE: 'The coffee shop',
        PUBLIC_MAP_COFFEE_DESCRIPTION: 'To promote concentration or exchange',
        PUBLIC_MAP_SEA_TITLE: 'The sea',
        PUBLIC_MAP_SEA_DESCRIPTION: 'Shall we take a break?',
        PUBLIC_MAP_AMPHITHEATER_TITLE: 'The amphitheatre',
        PUBLIC_MAP_AMPHITHEATER_DESCRIPTION: 'Host or attend a conference',
        PUBLIC_MAP_CLIENT_TITLE: 'My officies',
        PUBLIC_MAP_QG_TITLE: 'Jamespot HQ',
        PUBLIC_MAP_QG_DESCRIPTION: 'Come to discover Jamespot and its collaborators',
        PRIVATE_MAP_MY_PLATFORM: 'My platform',
        PRIVATE_MAP_MY_OFFICE: 'My virtual offices',

        // page coming later
        COMING_LATER:
            'The gods of the metarvers have not yet created this part of the universe. Feel free to come back later!',
        // if modified, change default meta tag in index.html
        META_TITLE: 'Jamespot Land | The metaverse for professionals by Jamespot',
        META_DESCRIPTION:
            'Discover Jamespot Land, the first metaverse for professionals. An innovative digital environment to optimise your internal communication.',
    },
};

export function getNavigatorLanguage() {
    return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language || 'fr';
}

export function getLang(locale: string) {
    return locale in LANG ? LANG[locale as 'fr' | 'en'] : LANG.fr;
}
