export function getWAUri(slug: string, type: string, id: string, startingPoint?: string) {
    return `${window.__RUNTIME_CONFIG__.WA_BASE_URL}/@/${slug}/${type}/${id}${startingPoint ? `${startingPoint}` : ''}`;
}

export function checkIsWARoute(pathname: string): boolean {
    return pathname.startsWith('/map');
}

export function isPublicMap(pathname: string): boolean {
    return pathname.startsWith('/map/public');
}
