import React from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { socket } from '../../utils/socket';
import { checkIsWARoute, getWAUri, isPublicMap } from '../../utils/workadventure';
import { checkAccessWA } from '../../utils/api';
import { Loader } from '../common/Loader';
import { Retry } from '../Retry';
import { useIntl } from 'react-intl';
import { UserContext } from '../../context/UserContext';
import { Connect } from '../Connect';
import { TopBar } from '../TopBar';
import { Footer } from '../Footer';

export function LocationLayout() {
    const location = useLocation();

    const [failedReason, setFailedReason] = React.useState<string | undefined>();
    const [failed, setFailed] = React.useState(false);
    const [accessPending, setAccessPending] = React.useState(true);
    const [allowed, setAllowed] = React.useState(false);
    const userContextObject = React.useContext(UserContext)[0];
    const intl = useIntl();
    const isWARoute = React.useMemo(() => checkIsWARoute(location.pathname), [location.pathname]);

    const { slug, type, id } = useParams<{ slug: string; type: string; id: string }>();

    /**
     * check the access right of the map (ie the user has access to this private map)
     */
    const checkAccessRights = React.useCallback(() => {
        if (!isWARoute) {
            setAllowed(true);
            setTimeout(() => setAccessPending(false), 1500);
            return;
        }

        if (!slug || !type || !id) {
            // problem
            return;
        }

        const url = getWAUri(slug, type, id);
        if (url && (userContextObject.user?.id || isPublicMap(location.pathname))) {
            setAccessPending(true);
            setFailed(false);
            setAllowed(false);
            checkAccessWA(url, userContextObject.user?.id)
                .then((allowedObj) => {
                    setAllowed(true);
                    setTimeout(() => setAccessPending(false), 1500);
                })
                .catch((e) => {
                    setTimeout(() => setAccessPending(false), 100);
                    setFailed(true);
                    setFailedReason(e.reason);
                });
        } else {
            setTimeout(() => setAccessPending(false), 100);
            setFailed(true);
            setFailedReason('NOT_LOGGED');
        }
    }, [isWARoute, location.pathname, userContextObject.user, slug, type, id]);

    /**
     * Attach to socket once availability and access checks are ok
     */
    React.useEffect(() => {
        if (allowed) {
            if (!socket.connected) {
                socket.connect();
            }
            socket.emit('goto', { map: location.pathname });

            socket.on('disconnect', () => {
                socket.connect();
                socket.emit('goto', { map: location.pathname });
            });
        }
    }, [allowed, location.pathname]);

    /**
     * Perform availability and access right checks on location change
     */
    React.useEffect(() => {
        socket.off('disconnect');
        setFailed(false);

        if (isWARoute) {
            checkAccessRights();
        } else {
            setAccessPending(false);
            setAllowed(true);
            socket.emit('goto', { map: location.pathname });

            socket.on('disconnect', () => {
                socket.connect();
                socket.emit('goto', { map: location.pathname });
            });
        }
    }, [location, checkAccessRights, isWARoute]);

    return (
        <>
            {/* PENDING */}
            {accessPending && <Loader postPending={false} />}

            {/* ALLOWED */}
            {!accessPending && allowed && <Outlet />}

            {/* JLAND IS FULL */}
            {!accessPending && failed && failedReason === 'TOO_MANY_USERS' && (
                <Retry
                    buttonLabel={intl.formatMessage({ id: 'REFRESH' })}
                    message={intl.formatMessage({ id: 'JLAND_FULL' })}
                    checkAvailability={checkAccessRights}
                />
            )}

            {/* NOT ALLOWED */}
            {!accessPending && failed && failedReason === 'NOT_ALLOWED' && (
                <Retry
                    buttonLabel={intl.formatMessage({ id: 'REFRESH' })}
                    message={intl.formatMessage({ id: 'MAP_FORBIDDEN' })}
                    checkAvailability={checkAccessRights}
                />
            )}

            {/* USER IS UNKNOWN */}
            {!accessPending && failed && failedReason === 'NOT_LOGGED' && (
                <>
                    <TopBar />
                    <main>
                        <div className="retry">
                            <Connect />
                        </div>
                    </main>
                    <Footer />
                </>
            )}
        </>
    );
}
