import React from 'react';
import { WorkAdventureIframe } from '../components/WorkAdventureIframe';
import { useIntl } from 'react-intl';
import '../style/summit.css';
// import { AlternateImages } from '../components/AlternateImage';

import summitLogo from '../resources/jamespot_summit.svg';
// import easisLogo from '../resources/summit_partenaires/LogoEasis_blanc_recadre.png';
// import stephanieLogo from '../resources/summit_partenaires/Stephanie_ORANGE_logo_recadre_white.png';
// import { getMessages } from '../utils/api';

export function Summit() {
    const intl = useIntl();
    // const [message, setMessage] = React.useState('');

    // const fetchAndSetMessage = () => {
    //     getMessages('summit').then((data) => {
    //         setMessage(data.footer ?? '');
    //     });
    // };

    // useEffect(() => {
    //     fetchAndSetMessage();
    //     const intervale = window.setInterval(fetchAndSetMessage, 10000);
    //     return () => {
    //         window.clearInterval(intervale);
    //     };
    // }, []);

    return (
        <>
            <WorkAdventureIframe
                slug="public"
                type="jlandmap"
                id="290323"
                label={intl.formatMessage({ id: 'SUMMIT' })}
                className="summit-iframe"
            />
            <div className="summit-footer-container">
                <div className="summit-footer">
                    <div className="summit__footer__jamespot">
                        <img src={summitLogo} alt="jamespot summit logo" />
                    </div>
                    {/* <div className="summit__footer__message" id="scroll-container">
                        <p id="scroll-text">{message}</p>
                    </div> */}
                    {/* {message?.length === 0 && (
                        <div className="summit__footer__partenaires">
                            {/* <p>Avec le soutien de :</p>
                            <AlternateImages
                                images={
                                    [
                                        // { img: stephanieLogo, alt: 'stephanie orange' },
                                        // { img: easisLogo, alt: 'easis' },
                                    ]
                                }
                                period={300}
                            /> /}
                        </div>
                    )} */}
                </div>
            </div>
        </>
    );
}
