import React, { ReactNode } from 'react';
import { getUser } from '../utils/api';

export type User = { id: string; isJamespot: boolean };
export type UserContextObject = {
    user: User | undefined;
    loading: boolean;
};

export type UserContextType = [UserContextObject, React.Dispatch<React.SetStateAction<UserContextObject>>];

const DEFAULT_CONTEXT = {
    user: undefined,
    loading: true,
};

export const UserContext = React.createContext<UserContextType>([
    DEFAULT_CONTEXT,
    () => {
        /**/
    },
]);

export const UserContextProvider = (props: { children: ReactNode }) => {
    const [userContextObject, setUserContextObject] = React.useState<UserContextObject>(DEFAULT_CONTEXT);
    React.useEffect(() => {
        setUserContextObject((ctx) => ({
            user: ctx.user,
            loading: true,
        }));
        getUser().then((user) => {
            if (user?.id) {
                setUserContextObject({
                    user: user,
                    loading: false,
                });
            } else {
                setUserContextObject({
                    user: undefined,
                    loading: false,
                });
            }
        });
    }, [setUserContextObject]);
    return (
        <UserContext.Provider value={[userContextObject, setUserContextObject]}>{props.children}</UserContext.Provider>
    );
};
