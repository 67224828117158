import jlo1bv2 from '../resources/maps_screenshot/jlo1_bureaux2.png';
import jlo2qg2 from '../resources/maps_screenshot/jlo2_qg2.png';
import jlo3cowork2 from '../resources/maps_screenshot/jlo3_coworking2.png';
import jlo5cyber from '../resources/maps_screenshot/jlo5_cyberpunk.png';
import jlmo2softpop from '../resources/maps_screenshot/jlmo2_softpop.png';
import jlv3japan from '../resources/maps_screenshot/jlv3_japon.png';
import { Img } from '../components/img/Img';
import '../style/map-presentation.css';

export function MapPresentation() {
    const mapsScreenshots = [
        { img: jlo1bv2, description: 'bureaux - JLO1' },
        { img: jlo2qg2, description: 'QG - JLO2' },
        { img: jlo3cowork2, description: 'coworking - JLO3' },
        { img: jlo5cyber, description: 'Cyberpunk - JLO5' },
        { img: jlmo2softpop, description: 'SoftPop - JLMO2' },
        { img: jlv3japan, description: 'Japon - JLV3' },
    ];

    return (
        <>
            <div className="map-presentation__title">
                <h1>Présentation des maps disponnibles</h1>
            </div>
            <div className="map-presentation__grid">
                {mapsScreenshots.map((screenshot) => {
                    return (
                        <div key={screenshot.img}>
                            <div>
                                <Img src={screenshot.img} alt="Illustration de la map bureaux virtuels de type jlo1" />
                            </div>
                            <p>{screenshot.description}</p>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
