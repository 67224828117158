import React from 'react';

export function PrivacyPolicy() {
    return (
        <div className="max-width">
            <h1>Protection des données personnelles</h1>
            <h2>Politique de confidentialité du site "www.jamespot.land"</h2>

            <p>
                JAMESPOT s’engage à respecter la confidentialité et plus généralement à protéger les données
                personnelles vous concernant, qui sont collectées et traitées à l'occasion de votre utilisation du site
                accessible à l’adresse URL "www.jamespot.land" (ci-après le « Site »). A cet égard, JAMESPOT respecte
                les législations européenne et française en matière de protection des données personnelles,
                principalement le règlement européen UE n°2016/679 dit « RGPD » et la loi n°78-17 dite « Informatique et
                Libertés ».
            </p>
            <p>
                Dans ce contexte, la Société JAMESPOT (dont le siège social est 66, rue Marceau, 93100 Montreuil,
                inscrite au RCS de Bobigny sous le numéro 483321378) est le responsable du traitement au sens du RGPD,
                pour la collecte et le traitement des données à caractère personnel effectués sur le présent Site.
            </p>

            <h3>Finalités des traitements</h3>

            <p>
                Vos données personnelles sont susceptibles d’être collectées et traitées par le biais du Site pour les
                finalités suivantes :
            </p>

            <p>Les formulaires présents sur le Site ont les finalités suivantes :</p>

            <ul>
                <li>Utiliser les services offerts par JAMESPOT notamment jamespot.pro et jamespot.land ;</li>
                <li>Prendre contact avec nos équipes commerciales et de clientèles ;</li>
                <li>Planifier une démonstration ;</li>
                <li>Télécharger des contenus ;</li>
                <li>S’inscrire à des événements.</li>
            </ul>

            <p>
                Les informations recueillies à partir de ces formulaires sont également susceptibles d’être traitées par
                JAMESPOT pour la gestion et l’envoi de newsletters et d’e-mail marketing.
            </p>
            <p>
                Le Site appose des cookies, le cas échéant avec votre accord. Les données collectées au moyen de ces
                cookies, les finalités de leur collecte, destinataires des données et durées de conservation sont
                détaillées dans la Politique de Cookies du Site.
            </p>
            <p>
                Des données de navigation des visiteurs du Site qui sont collectées au travers des données de connexion
                (logs) générées par le serveur web du Site sont conservées à des fins de sécurité, pendant une durée
                strictement nécessaire à cette finalité. Ces données sont destinées aux services de JAMESPOT en charge
                d'assurer la sécurité du Site.
            </p>
            <p>
                Les données que vous saisissez sont nécessaires à la gestion des relations commerciales et dans les cas
                où vous souscrivez à ses services à l’exécution du contrat conclu entre vous et JAMESPOT, et
                correspondent également à l’intérêt légitime de JAMESPOT de présenter et d’améliorer ses services.
            </p>

            <h3>Destinataires des données personnelles</h3>

            <p>
                Les services internes de JAMESPOT sont les seuls destinataires des données traitées pour les finalités
                susmentionnées.
            </p>

            <p>Durée de conservation</p>

            <p>
                Les données des visiteurs du Site, clients ou prospects de JAMESPOT sont conservées pendant le temps
                nécessaire à la gestion de la relation commerciale et au traitement de leur(s) demande(s), selon les
                finalités poursuivies. Les données des clients utilisées à des fins de prospection commerciale peuvent
                être conservées pendant un délai de trois ans à compter de la fin de la relation commerciale. Les
                données des visiteurs du Site, prospects non client de JAMESPOT, sont conservées durant le temps
                nécessaire à la gestion de la relation commerciale, et pendant un délai de trois ans à compter de leur
                collecte ou du dernier contact émanant du prospect, sauf accord pour une durée plus longue.
            </p>
            <p>
                Toutes les données permettant d’établir la preuve d’un droit ou d’un contrat, ou conservées au titre du
                respect d’une obligation légale et/ou règlementaire, peuvent faire l’objet d’une politique d’archivage,
                et être conservées à ces fins conformément aux dispositions légales et/ou règlementaires en vigueur.
            </p>

            <h3>Vos droits</h3>

            <p>
                Conformément à la loi n°78-17 du 6 janvier 1978 et au RGPD, vous disposez d'un droit d'accès, de
                rectification ou d'effacement des données à caractère personnel vous concernant, et, le cas échéant,
                d'un droit à la portabilité de vos données. Vous pouvez demander la limitation ou vous opposer au
                traitement de vos données, ou, le cas échéant, retirer votre consentement. Vous pouvez également définir
                des directives sur le sort de vos données après votre mort, par application de l'article 32 6° de la Loi
                modifiée n°78-17 du 6 Janvier 1978.
            </p>
            <p>
                Pour exercer ces droit, vous pouvez vous adresser à JAMESPOT en écrivant à l'adresse électronique
                suivante : <a href="mailto:dpo@jamespot.com">dpo@jamespot.com</a> ou par voie postale à l’adresse
                suivante : Jamespot - Service Marketing – 66 rue Marceau, Bâtiment C’ – 93100 Montreuil, en indiquant
                votre nom, prénom, e-mail et adresse.
            </p>
            <p>
                Conformément à la réglementation en vigueur, cette demande doit être signée et accompagnée de la
                photocopie d’un titre d’identité portant votre signature ainsi que l’adresse à laquelle doit parvenir la
                réponse. Une réponse sera alors adressée dans un délai d’un mois suivant la réception de la demande.
            </p>
            <p>
                Conformément à la loi, vous disposez du droit légal d'introduire toute réclamation auprès de l’autorité
                de contrôle compétente, en France la CNIL : <a href="https://www.cnil.fr/">https://www.cnil.fr/</a>
            </p>

            <h3>Sécurité des données collectées</h3>

            <p>
                JAMESPOT s’engage à assurer la confidentialité des données collectées, et à mettre en œuvre toutes les
                mesures techniques et organisationnelles adaptées pour préserver leur sécurité et leur intégrité,
                notamment contre la perte accidentelle, l’altération, la diffusion ou l’accès non autorisés.
            </p>
            <p>
                D’une façon générale, JAMESPOT s’engage à utiliser les données collectées dans le strict respect de la
                législation en vigueur, et notamment du RGPD.
            </p>

            <p>À ce titre JAMESPOT s’engage notamment à :</p>

            <ul>
                <li>Ne pas utiliser les données à des fins autres que celles spécifiées aux présentes ;</li>
                <li>
                    Informer les utilisateurs de toute violation ou faille de sécurité ayant des conséquences directes
                    ou indirectes sur les données et/ou susceptible d’entraîner accidentellement la divulgation ou
                    l’accès non autorisé à des données.
                </li>
            </ul>

            <h3>Dernière mise à jour</h3>

            <p>18 juillet 2022</p>
        </div>
    );
}
