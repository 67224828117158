import React from 'react';
import { FormattedMessage } from 'react-intl';
import './discover-jland-banner.css';
import { Href } from '../button/Href';
import { J_URL } from '../../utils/const';

export function DiscoverJLandBanner() {
    return (
        <div className="jland-banner">
            <div className="max-width grid">
                <div className="jland-banner-content">
                    <p>
                        <FormattedMessage id="TITLE" />
                        <br />
                        <FormattedMessage id="TITLE_COMPLEMENT" />
                    </p>
                </div>
                <div className="jland-banner-content">
                    <Href to="/village">
                        <FormattedMessage id="DISCOVER_JLAND" />
                    </Href>
                    <Href to={J_URL.LAUNCH_OFFICE} outline>
                        <FormattedMessage id="CREATE_MY_VIRTUAL_OFFICE" />
                    </Href>
                </div>
            </div>
        </div>
    );
}
